import { ApiService } from '../../../core/service/api.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from 'src/app/core/models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  refreshLang = new BehaviorSubject<any>(null);
   public currentUserSubject: BehaviorSubject<any>;
  public currentPermissions: BehaviorSubject<any> = new BehaviorSubject<any[]>(
    []
  );

  userProfileChanged = new Subject<any>();

  public currentUser: Observable<any>;
  public authUser: any;
  public user: any = {};

  constructor(private apiService: ApiService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('authUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    window.addEventListener(
      'storage',
      () => {
        const userToken = JSON.parse(localStorage.getItem('userToken'));
        if (
          // This condition to prevent infinit loading
          (this.currentUserValue &&
            localStorage.getItem('userToken') &&
            this.currentUserValue.user_token !== userToken) ||
          (this.currentUserValue && !localStorage.getItem('userToken')) ||
          (!this.currentUserValue && localStorage.getItem('userToken'))
        ) {
          window.location.reload();
        }
      },
      false
    );
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  refreshProfileData(model) {
    this.userProfileChanged.next(model);

    let authUserAsString: string = localStorage.getItem('authUser');
    let authUser = JSON.parse(authUserAsString)
    authUser.fullName = model.user_first_name + ' ' + model.user_father_name + ' ' +  model.usre_grandfather_name + ' ' + model.user_family_name;
    authUser.profile_img = model.logo;
    localStorage.setItem('authUser', JSON.stringify(authUser));
  }

  login(email, password) {
    const modal = {
      email,
      password,
    };
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/login`, modal)
      .pipe(
        map((user) => {
          localStorage.setItem('authUser', JSON.stringify(user));
          localStorage.setItem('cabToken', JSON.stringify(user.cab_token));
          localStorage.setItem('userToken', JSON.stringify(user.user_token));
          localStorage.setItem('token', JSON.stringify(user.access_token));
          localStorage.setItem('firstName', user.first_name);
          localStorage.setItem('lastName', user.family_name);
          this.currentUserSubject.next(user);

          return user;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  checkUniqueEmail(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/check_unique`, modal)
      .pipe(catchError(this.handleError));
  }

  forgetPassword(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/forgetpass`, modal)
      .pipe(catchError(this.handleError));
  }

  resetPassword(userId, code, newPassword) {
    return this.apiService.post(`${environment.apiUrl}/User/ResetPassword`, {
      userId,
      token: code,
      newPassword,
    });
  }

  ChangePassword(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/new_password`, modal)
      .pipe(catchError(this.handleError));
  }

  checkResetPasswordLink(userId, code) {
    return this.apiService.post(`${environment.apiUrl}/User/VerifyToken`, {
      userId,
      token: code,
    });
  }

  confrimEmail(modal) {
    return this.apiService
      .get(`${environment.apiUrl}/api/auth/check_code`, modal)
      .pipe(catchError(this.handleError));
  }

  registerUser(obj): any {
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/register`, obj)
      .pipe(catchError(this.handleError));
  }

  getUserType() {
    return this.apiService.get(`${environment.apiUrl}/api/auth/get_users_type`);
  }

  getCountryCode() {
    return this.apiService
      .get(`${environment.apiUrl}/api/auth/get_country_code`)
      .pipe(catchError(this.handleError));
  }

  resendVerification(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/resend_verification`, modal)
      .pipe(catchError(this.handleError));
  }

  checkVerification(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/check_verification_token`, model)
      .pipe(catchError(this.handleError));
  }

  validateExpiryToken(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/auth/forget_token`, model)
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    return throwError(error);
  }

  hasPermission(permission: string) {
    const accessControls: [] = this.currentPermissions.getValue();
    const founded = [];
    const permissionsList = permission.split(',');
    permissionsList.forEach(one => {
      const found = accessControls.some(control => control === one);
      if (found) { founded.push(one); }
    });
    if (founded.length && accessControls.length) { return true; }
    else { return false; }
  }

}
