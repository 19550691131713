import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apiService: ApiService) {}

  getRoleToAssign(employeeToken) {
    return this.apiService
      .get(
        `${environment.apiUrl}/api/system/get_all_role_to_assign_user/${employeeToken}`
      )
      .pipe(catchError(this.handleError));
  }

  onAssignNewRole(modal) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/assign_role_to_user_profile`,
        modal
      )
      .pipe(catchError(this.handleError));
  }

  removeAssignedRole(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/remove_role`, model)
      .pipe(catchError(this.handleError));
  }
  removeCabAdmin(model) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/unassign_user_from_profile`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  getPersonalInfo(userToken) {
    return this.apiService
      .get(`${environment.apiUrl}/api/system/personal_info/${userToken}`)
      .pipe(catchError(this.handleError));
  }

  getCABInfo(userToken) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab_baisc_info`, userToken)
      .pipe(catchError(this.handleError));
  }

  getEducationList(userToken) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_educations`, userToken)
      .pipe(catchError(this.handleError));
  }

  getLanguageSkillData(userToken) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/list_profile_languages`,
        userToken
      )
      .pipe(catchError(this.handleError));
  }

  getlanguageList(userToken) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_languages`, userToken)
      .pipe(catchError(this.handleError));
  }

  getOtherSkillData(userToken) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/list_profile_other_skills`,
        userToken
      )
      .pipe(catchError(this.handleError));
  }

  getYearsOfExp() {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_years_of_experience`)
      .pipe(catchError(this.handleError));
  }

  getPresentPositionData(userToken) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_present_position`, userToken)
      .pipe(catchError(this.handleError));
  }

  getProExpData(userToken) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/list_profile_experience`,
        userToken
      )
      .pipe(catchError(this.handleError));
  }

  getTraningData(userToken) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_profile_training`, userToken)
      .pipe(catchError(this.handleError));
  }

  getBankAccountData(userToken) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/list_profile_bank_accounts`,
        userToken
      )
      .pipe(catchError(this.handleError));
  }

  deleteBankAccount(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/remove_bank_account`, model)
      .pipe(catchError(this.handleError));
  }

  getCVData(userToken) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_cv`, userToken)
      .pipe(catchError(this.handleError));
  }
  getConfidentialData(userToken) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_confidntial`, userToken)
      .pipe(catchError(this.handleError));
  }

  getCountryCode() {
    return this.apiService
      .get(`${environment.apiUrl}/api/auth/get_country_code`)
      .pipe(catchError(this.handleError));
  }

  editPersonalInfo(model) {
    const formData = new FormData();
    if (model.nationality_file && model.nationality_file.length) {
      model.nationality_file.forEach((file) => {
        formData.append('nationality_file', file, file.name);
      });
    }
    if (model.passport_file && model.passport_file.length) {
      model.passport_file.forEach((file) => {
        formData.append('passport_file', file, file.name);
      });
    }
    for (const prop in model) {
      if (
        model.hasOwnProperty(prop) &&
        prop !== 'nationality_file' &&
        prop !== 'passport_file'
      ) {
        formData.append(prop, model[prop]);
      }
    }
    return this.apiService
      .post(`${environment.apiUrl}/api/system/update_info`, formData)
      .pipe(catchError(this.handleError));
  }

  updateMybillingAddress(model) {
    const formData = new FormData();
    for (const prop in model) {
      formData.append(prop, model[prop]);
    }
    return this.apiService
      .post(`${environment.apiUrl}/api/system/update_my_billing_address`, model)
      .pipe(catchError(this.handleError));
  }

  addEducation(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_new_educations`, modal)
      .pipe(catchError(this.handleError));
  }

  editEducation(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_educations`, modal)
      .pipe(catchError(this.handleError));
  }

  addLanguageSkill(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_new_language`, modal)
      .pipe(catchError(this.handleError));
  }

  editLanguageSkill(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_profile_language`, modal)
      .pipe(catchError(this.handleError));
  }

  addOtherSkill(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_new_other_skills`, modal)
      .pipe(catchError(this.handleError));
  }

  editOtherSkill(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_other_skills`, modal)
      .pipe(catchError(this.handleError));
  }

  addPresentPosition(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_present_position`, modal)
      .pipe(catchError(this.handleError));
  }

  editPresentPosition(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_present_position`, modal)
      .pipe(catchError(this.handleError));
  }

  addProExp(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_new_experience`, modal)
      .pipe(catchError(this.handleError));
  }

  editProExp(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_experience`, modal)
      .pipe(catchError(this.handleError));
  }

  addTraining(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_new_training`, modal)
      .pipe(catchError(this.handleError));
  }

  editTraining(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_training`, modal)
      .pipe(catchError(this.handleError));
  }

  addBankAccount(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_new_bank_account`, modal)
      .pipe(catchError(this.handleError));
  }

  editBankAccount(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_bank_account`, modal)
      .pipe(catchError(this.handleError));
  }

  addCV(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_cv`, modal)
      .pipe(catchError(this.handleError));
  }

  editCV(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_cv`, modal)
      .pipe(catchError(this.handleError));
  }

  addConfidential(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_confidntial`, modal)
      .pipe(catchError(this.handleError));
  }

  editConfidential(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_confidntial`, modal)
      .pipe(catchError(this.handleError));
  }

  deleteCV(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/delete_cv`, modal)
      .pipe(catchError(this.handleError));
  }

  activateAndDeactivate(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/approve_user`, modal)
      .pipe(catchError(this.handleError));
  }

  deleteEducation(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/delete_educations`, modal)
      .pipe(catchError(this.handleError));
  }

  deleteLanguage(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/remove_language`, modal)
      .pipe(catchError(this.handleError));
  }

  deleteOtherSkill(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/remove_other_skills`, modal)
      .pipe(catchError(this.handleError));
  }

  deletePresentPosition(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/delete_present_position`, modal)
      .pipe(catchError(this.handleError));
  }

  deleteProExp(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/remove_experience`, modal)
      .pipe(catchError(this.handleError));
  }

  deleteTraining(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/remove_training`, modal)
      .pipe(catchError(this.handleError));
  }

  deleteUser(modal) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/delete_user`, modal)
      .pipe(catchError(this.handleError));
  }

  submitAllUserChanges(modal) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/user_submit_all_changes_to_admin`,
        modal
      )
      .pipe(catchError(this.handleError));
  }

  cancelAllUserChanges(modal) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/user_cancel_all_his_changes`,
        modal
      )
      .pipe(catchError(this.handleError));
  }

  cancelChanges(modal, tabName) {
    let apiAction;
    switch (tabName) {
      case 'education':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_decline_user_changes_education`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;
      case 'language':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_decline_user_changes_lang`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      case 'otherSkills':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_decline_user_changes_skills`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      case 'bankAccount':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_decline_user_changes_bank`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      case 'training':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_decline_user_changes_training`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      case 'cv':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_decline_user_changes_cv`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;
      case 'presentPosition':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_decline_user_changes_pp`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;
      case 'personalInfo':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_decline_user_changes_pi`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;
      case 'proExp':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_decline_user_changes_experience`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      case 'Confidential':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_decline_user_changes_confidntial`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      default:
        break;
    }
    return apiAction;
  }

  approveChanges(modal, tabName) {
    let apiAction;
    switch (tabName) {
      case 'education':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_approve_user_changes_education`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;
      case 'language':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_approve_user_changes_lang`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      case 'otherSkills':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_approve_user_changes_skills`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;
      case 'training':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_approve_user_changes_training`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      case 'bankAccount':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_approve_user_changes_bank`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      case 'cv':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_approve_user_changes_cv`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;
      case 'presentPosition':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_approve_user_changes_pp`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;
      case 'personalInfo':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_approve_user_changes_pi`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;
      case 'proExp':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_approve_user_changes_experience`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      case 'Confidential':
        apiAction = this.apiService
          .post(
            `${environment.apiUrl}/api/system/admin_approve_user_changes_confidntial`,
            modal
          )
          .pipe(catchError(this.handleError));
        break;

      default:
        break;
    }
    return apiAction;
  }

  getMonthsList() {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_months`)
      .pipe(catchError(this.handleError));
  }

  uploadImage(fileToUpload, userToken) {
    if (fileToUpload && fileToUpload.name) {
      const formData = new FormData();
      formData.append('img_profile', fileToUpload, fileToUpload.name);
      formData.append('user_token', userToken);
      return this.apiService
        .post(`${environment.apiUrl}/api/system/profile_img`, formData)
        .pipe(catchError(this.handleError));
    }
  }

  getAssignedTraining(filter) {
    return this.apiService
      .post(
        `${environment.apiUrl}/api/system/list_all_assigned_training`,
        filter
      )
      .pipe(catchError(this.handleError));
  }

  handleError(error) {
    return throwError(error);
  }
}
