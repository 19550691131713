import {CabListSortList} from '../enum/cabListSortList.enum';

export class CabListFilterModel {
  page_number: number;
  per_page: number;
  order_type: string = 'ASC';
  order_by: CabListSortList = 0;
  user_token: string;
  // status: string;
  // country: string;
  // name: string;
  search_key: string;
  from_date: number;
  to_date: number;
  status: string = '0';
  country_token: string;
  scope_array: string[];
}
