import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CabsListService {
  constructor(private apiService: ApiService) {}

  // getMyCABsList(model) {
  //   return this.apiService
  //     .post(`${environment.apiUrl}/api/system/list_all_my_cab_profiles`, model)
  //     .pipe(catchError(this.handleError));
  // }
  getAllCABsList(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/list_all_my_cab_profiles`, model)
      .pipe(catchError(this.handleError));
  }

  getAllAccreditationBoidesList(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/accreditation_bodies_system`, model)
      .pipe(catchError(this.handleError));
  }

  // arAaccCertificate
  directPublish(modal,enAaccCertificate,accScopes) {
    const formData = new FormData();
    enAaccCertificate.forEach(file => {
      formData.append('certificate_file', file, file.name);
    });
    // arAaccCertificate.forEach(file => {
    //   formData.append('certificate_ar_file', file, file.name);
    // });
    accScopes.forEach(file => {
      formData.append('scopes_file', file, file.name);
    });

    for (const prop in modal) {
      if (prop === 'scopes') {
        formData.append(prop, JSON.stringify(modal[prop]));
      } else {
        formData.append(prop, modal[prop]);
      }
    }

    return this.apiService
      .post(`${environment.apiUrl}/api/system/add_new_cab_admin`, formData)
      .pipe(catchError(this.handleError));
  }

  exportAccreditationBoidesAsExcel(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/accreditation_bodies_system_export`,
        model
      )
      .pipe(catchError(this.handleError));
  }
  exportAccreditationBoidesAsPdf(model) {
    return this.apiService
      .getDownload(
        `${environment.apiUrl}/api/system/accreditation_bodies_system_export_pdf`,
        model
      )
      .pipe(catchError(this.handleError));
  }

  uploadImageForCab(fileToUpload) {
    if (fileToUpload && fileToUpload.name) {
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      return this.apiService
        .post(`${environment.apiUrl}/api/system/upload_cab_logo`, formData)
        .pipe(catchError(this.handleError));
    }
  }

  handleError(error) {
    return throwError(error);
  }
}
