// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // gac
  // apiUrl: 'https://gacapi.mosandah.com.sa',
  // wsUrl: 'wss://gacapi.mosandah.com.sa',
  // wsUrl: 'wss://gacapi.mosandah.com.sa:433',

  // gac stage
  apiUrl: 'https://apistage.gac.org.sa',
  wsUrl: 'wss://apistage.gac.org.sa',

  // gac production
  // apiUrl: 'https://api.gac.org.sa',
  // wsUrl: 'wss://api.gac.org.sa',

  // gac migration (Dev)
  // apiUrl: 'https://gac-migrationapi.mosandah.com.sa',
  // wsUrl: 'wss://gac-migrationapi.mosandah.com.sa',

  // gac migration stage
  // apiUrl: 'https://apistage.gac.org.sa',
  // wsUrl: 'wss://apistage.gac.org.sa',

  // gac migration production
  // apiUrl: 'https://api.gac.org.sa',
  // wsUrl: 'wss://api.gac.org.sa',




  apiUrlTest: 'http://laravel.aioqr.online',
  googleApiKey: '6Lfpg9UbAAAAAPUD-yVlkRcIMWcHH7aOrSVLbSRk',
  googleSiteKey: '6Lfpg9UbAAAAAPUD-yVlkRcIMWcHH7aOrSVLbSRk',
};



// export const environment = {
//   production: true,
//   apiUrl: 'https://egacstapi.expertapps.com.sa',
//   apiUrlTest: 'http://laravel.aioqr.online',
//   googleApiKey: '6Lfpg9UbAAAAAPUD-yVlkRcIMWcHH7aOrSVLbSRk',
//   googleSiteKey: '6Lfpg9UbAAAAAPUD-yVlkRcIMWcHH7aOrSVLbSRk',
//   wsUrl: 'wss://egacstapi.expertapps.com.sa:443',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
